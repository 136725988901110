import { Avatar, IconButton, ListItemButton, ListItemText, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';

const UserContainer = styled(Box, { shouldForwardProp: prop => prop !== 'full' })(({ theme, open, full }) => ({
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(full ? 8 : 4),
    backgroundColor: theme.palette.common.background,
    ...(open && {
        borderRadius: '12px',
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3, 4),
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 1px 2px #B6C7D6',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
        minWidth: 0
    })
}));

const UserAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: theme.palette.navigation.background.selected,
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(16)
}));

const Username = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightSemiBold,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    marginRight: theme.spacing(4)
}));

const EllipsesButton = styled(IconButton)(() => ({
    width: '24px !important',
    height: '24px !important',
    flexShrink: 0
}));

const UserOptionButton = styled(ListItemButton)(({ theme }) => ({
    borderRadius: 0,
    span: { width: '100%' },
    '& .MuiTypography-root': { marginLeft: theme.spacing(2) }
}));

const SelectableListItemText = styled(ListItemText)(({ theme, selected }) => ({
    marginLeft: selected ? theme.spacing(3) : theme.spacing(9)
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '18px'
}));

const Info = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(12),
    lineHeight: '12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%'
}));

const UserInfoBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    minWidth: 0
}));

export { EllipsesButton, Info, InfoLabel, SelectableListItemText, UserAvatar, UserContainer, UserInfoBox, Username, UserOptionButton };
